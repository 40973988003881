import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.API_URL,
});
export const api_voucher = axios.create({
  baseURL: process.env.API_VOUCHER_URL,
});

api.defaults.timeout = 10000000;
api_voucher.defaults.timeout = 10000000;

if (typeof window !== 'undefined') {
  api.interceptors.request.use(
    async (config) => {
      const token = sessionStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
  api_voucher.interceptors.request.use(
    async (config) => {
      const token = sessionStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );
}
api_voucher.defaults.headers.post['Content-Type'] = 'application/json';
api.defaults.headers.post['Content-Type'] = 'application/json';

export default { api, api_voucher };
